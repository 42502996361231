<template>
    <NoNavLayout noside>
        <div class="d-flex justify-content-center align-items-center px-3 py-5 h-100">
            <Wrapper :heading="$t('global.myAccountArea')" center noinfo contain
                normal>
                <div class="d-flex flex-column align-items-center mb-3 px-2">
                    <div class="d-grid gap-2 w-100">
                        <v-form>
                            <v-row>
                                <v-col>
                                    <input-field class="header-span" type="special-text" color="white"
                                        :props="{ styleType: 'outlined' }" v-model="username" required
                                        :label="$t('global.username')" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <input-field class="header-span" type="password" color="white"
                                        :props="{ styleType: 'outlined' }" v-model="password" required
                                        :label="$t('global.password')" />
                                </v-col>
                            </v-row>
                        </v-form>
                        <div v-if="authError" class="d-grid w-100 error-message">
                            <p>{{ authError }}</p>
                        </div>

                        <v-btn :disabled="!username || !password" class="btn btn-danger px-3 my-3"
                            @click="login(username, password)">{{
                                $t('global.signIn') }}</v-btn>
                        <button class="btn btn-outline-danger px-3" @click="goToRegistrationPage(false)">
                            {{ $t('global.createAccount') }}
                        </button>
                        <button v-if="!mSite" class="btn btn-outline-danger px-3" @click="goToRegistrationPage(true)">
                            {{ $t('global.createTrialAccount') }}
                        </button>
                    </div>
                    <div v-if="!mSite" class="d-flex w-100 mt-4 justify-space-between">
                        <p @click="goToForgetPassPage" style="cursor: pointer;">{{ $t('global.forgotPassword') }}?</p>
                        <p v-if="loadedAsIframe" @click="handelCancelClick" style="cursor: pointer;">{{ $t('global.cancel')
                        }}</p>
                    </div>
                </div>
            </Wrapper>
        </div>
    </NoNavLayout>
</template>

<script>
export default {
    components: {
        Wrapper: () => import('@/components/Wrapper.vue'),
        NoNavLayout: () => import('@/layouts/NoNavLayout.vue'),
    },
    data() {
        return { username: null, password: null, authError: null };
    },
    props: {
        login: Function,
        goToRegistrationPage: Function,
        goToForgetPassPage: Function,
        handelCancelClick: Function,
        loadedAsIframe: Boolean,
        createdMethod: Function,
        mSite: Boolean
    },
    watch: {
        username() {
            this.authError = null;
        },
        password() {
            this.authError = null;
        }
    },
    created() {
        this.createdMethod()
    }
};
</script>
<style scoped>
.header-span {
    font-size: 12px;
    margin: 10px 0;
}

.default-class {
    color: white !important;
}

.error-message {
    color: #ff5252;
    font-size: 12px;
}</style>